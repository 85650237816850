import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useMatches, useOutlet, useRouteError } from "react-router-dom";
import { api, isset } from "../util/util";
import { API_CONNECTION } from "../config/Const";
import lazyImport from "../util/lazyImport.tsx";
import ScrollToTop from "../component/useScrollToTop.js";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom.js";

//遅延ロード
const Layout = lazyImport(() => import("../layout/MainLayout"));
const GenError = lazyImport(() => import("../page/error/GenError"));
const Login = lazyImport(() => import("../page/Login.js"));
const Panel = lazyImport(() => import("../page/panel/Panel.js"));
const Dashboard = lazyImport(() => import("../page/panel/Dashboard.js"));
const Account = lazyImport(() => import("../page/panel/Account.js"));
const Timeline = lazyImport(() => import("../page/panel/Timeline.js"));
const Project = lazyImport(() => import("../page/panel/Project.js"));
const Client = lazyImport(() => import("../page/panel/Client.js"));
const Tracking = lazyImport(() => import("../page/panel/Tracking.js"));
const TestMonitor = lazyImport(() => import("../page/panel/TestMonitor.js"));

const App = () => {
  const matches = useMatches();
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  if (!isset(matches[1])) {
    window.location.href = "/err/06";
    matches[1] = "";
  }

  useEffect(() => {
    //ローダー初期化
  }, [matches]);

  const outlet = useOutlet();
  return (
    <Layout>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <React.Fragment key={matches[1].pathname}>{outlet}</React.Fragment>
      </AnimatePresence>
    </Layout>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError();
  console.dir(error);

  return <GenError errCode="99" errMes={error.response.data.message} />;
};

const router = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "panel/",
        element: <Panel />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/panel/user` });
        },
        children: [
          {
            path: "index",
            element: <Dashboard />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/home`,
              });
            },
          },
          {
            path: "timeline/:prjCd?",
            element: <Timeline />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/timeline/${
                  params.prjCd ? params.prjCd : "0"
                }`,
              });
            },
          },
          {
            path: "settings/projects",
            element: <Project />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/set/project`,
              });
            },
          },
          {
            path: "settings/organization",
            element: <Client />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/set/client`,
              });
            },
          },
          {
            path: "settings/tracking/:prjCd?",
            element: <Tracking />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/set/tracking/${
                  params.prjCd ? params.prjCd : "0"
                }`,
              });
            },
          },
          {
            path: "test_monitor",
            element: <TestMonitor />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/test_monitor`,
              });
            },
          },
          {
            path: "accounts",
            element: <Account />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/panel/accounts?page=0`,
              });
            },
          },
        ],
      },
      {
        path: "secure/auth",
        element: <Login />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/secure/auth`,
          });
        },
      },
      {
        path: "*", // 存在しないパスのためのワイルドカード
        element: <GenError errCode="06" />,
      },
    ],
  },
  {
    //ERROR
    path: "/err/:errCode",
    element: <GenError />,
  },
];

export default router;
