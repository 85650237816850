export const LANG_MODE = "ja";

export const LANG = {
  TXT_EMAIL: "メールアドレス",
  TXT_MAIL: "メール",
  TXT_PASSWORD: "パスワード",
  TXT_NAME: "名前",
  TXT_EX: "例",
  TXT_NAME_OF: "の名前",
  TXT_CREATED: "登録日",
  TXT_AUTHORITY: "権限",
  TXT_TIMELINE: "タイムライン",
  TXT_SETTING: "設定",
  TXT_ACCOUNT: "アカウント",
  TXT_PROJECT: "プロジェクト",
  TXT_CODE: "識別コード",
  TXT_ORGANIZATION: "組織",
  TXT_TRACKING: "トラッキング",
  TXT_TRACKING_TEST: "トラッキングテスト",
  TXT_LOGOUT: "ログアウト",
  TXT_IMG: "画像",
  TXT_EVENT: "イベント",
  TXT_ACTION: "行動履歴",
  TXT_ADD: "作成",
  TXT_S_ADD: "の作成",
  TXT_SAVE: "保存",
  TXT_DISPLAY: "表示",
  TXT_PLUS: "追加",
  TXT_PERIOD: "期間",
  TXT_YMD_FORMAT: "年/月/日",
  TXT_HM_FORMAT: "時:分",
  TXT_LOADMORE: "さらに表示する",
  TXT_DELETE: "削除",
  TXT_CANCEL: "キャンセル",
  TXT_CANCELED: "キャンセル済み",
  TXT_SIGNIN: "サインイン",
  TXT_USER: "ユーザー",
  TXT_IP: "IPアドレス",
  TXT_MEMO: "メモ",
  TXT_UNKNOWN: "不明",
  TXT_CVINFO: "検知したパラメータ",
  TXT_PGEVENT: "検知したイベント",
  TXT_PINSID: "ピンのID",
  TXT_PINSLABEL: "ラベル",
  TXT_PINSPV: "ピン到達直近のPV数",
  TXT_SCROLL_PER: "スクロール",
  TXT_PAGE_TIME: "滞在時間",
  TXT_TITLE: "タイトル",
  TXT_DESCRIPTION: "概要",
  TXT_GENERAL: "一般",
  TXT_INVOICE: "ご請求先",
  TXT_COMPANY_NAME: "会社名",
  TXT_MANAGER: "担当者",
  TXT_LASTNAME: "姓",
  TXT_FIRSTNAME: "名",
  TXT_PHONE: "電話番号",
  TXT_ZIP: "郵便番号",
  TXT_PREF: "都道府県",
  TXT_ADDR01: "市区町村番地",
  TXT_ADDR02: "建物名・部屋番号",
  TXT_TARGET_DOMAIN: "利用先ドメイン",
  TXT_TARGET_DOMAIN_OPTION: "追加オプションのドメイン",
  TXT_PLAN: "ご利用プラン",
  TXT_TRACKING_TAG: "トラッキングタグ",
  TXT_TARGET_PAGE: "計測対象ページ",
  TXT_TARGET_PAGE_URL: "対象ページのURL",
  TXT_BLOCK_PAGE_URL: "除外ページのURL",
  TXT_PIN: "ピン",
  TXT_TRACKING_TAG_BASE: "計測タグ",
  TXT_TRACKING_TAG_PARAM: "パラメータタグ",
  TXT_URL_PART: "URLの一致",
  TXT_MATCH_TYPE: "一致条件",
  TXT_EVENT_NAME: "イベント名",
  TXT_EVENT_TYPE: "イベントの形式",
  TXT_EVENT_TARGET: "対象の要素",
  TXT_ATTRIBUTE_NAME: "属性の値",
  TXT_ATTRIBUTE_TYPE: "属性",
  TXT_EVENT_TARGET_PAGE_URL: "対象ページのURL",
  TXT_EVENT_PARAM: "追加設定値",
  TXT_PINN_NAME: "ピンの名前",
  TXT_PINN_CONDITION: "到達条件",
  TXT_CONDITION_AND: "AND",
  TXT_CONDITION_OR: "OR",
  TXT_PINN_CANCEL_SET: "ピン到達の自動キャンセル設定",
  TXT_CANCEL_SET: "キャンセル設定",
  TXT_PINN_CANCEL_PAGE: "キャンセルページのURL",
  TXT_PINN_CANCEL_TARGET_DAY: "キャンセル対象のピン",
  TXT_IGNITION: "の発生",
  TXT_RUN_TEST_MODE: "テストモードの起動",
  TXT_TEST_IP: "接続元IPアドレス",
  TXT_TEST_MODE_LOG: "テストログ",
  TXT_EDIT_LIMIT: "上限の変更",
  TXT_EDIT_PLAN_LIMIT: "プラン上限の変更",
  TXT_ADD_LIMIT_OF: "の上限追加",
  TXT_CHANGE_BEFORE_SET: "現在の設定",
  TXT_CHANGE_AFTER_SET: "変更後の設定",
  TXT_REQUEST_LIMIT_ADD: "上限の変更を申し込む",
  TXT_NEXT_MONTH_ONWARD: "翌月以降",

  TXT_SETTING_LINK: "リンクのURLルールを設定します",
  TXT_LINK_URL_RULE: "リンクのURLルール",
  TXT_SET_PIN_NOTE: "メモを設定してください",
  TXT_SETTING_NOTE: "このピンにメモを設定します",
  TXT_NO_HYPHEN: "ハイフンなし",
  TXT_EXPORT_ACTIONS: "このピンの行動データをエクスポートします",
  TXT_SHOW_URLS_HISTORY: "このURLの行動履歴を表示します",
  TXT_RECENT_PV: "直近のPV数",
  TXT_RECENT_CV: "直近のピン到達数",
  TXT_MONTHLY_CV: "今月のピン到達数",
  TXT_ALL_CV: "全ての期間のピン到達数",
  TXT_CVNUM: "ピン到達数",
  TXT_EVENT_NUM: "イベント登録数",
  TXT_DISPLAY_TO: "を表示中",
  TXT_SETTEINGS: "の設定",
  TXT_USERID_SEARCH_NOTICE: "シンクされたデータも同時に抽出します",
  TXT_URL_FULL: "URLパラメーターを省略",
  TXT_REGISTED_ITEM: "登録済みの",
  TXT_PINS_FROM_DAYS_AGO: "日前までのピン到達",
  TXT_SAME_USER: "同一ユーザーの",
  TXT_TERMINAL_IP: "現在アクセスしている端末のIPアドレスは",
  TXT_EXPIRE_TEST_MODE: "テストモードの有効期限",

  TXT_FLAG_ON: "有り",
  TXT_FLAG_OFF: "無し",
  TXT_DESU: "です。",

  HELP_LINK_URL_RULE_1:
    "リンクのURLルールを設定する事で、'ピンのID'をクリックした時の遷移先をカスタマイズできます。",
  HELP_LINK_URL_RULE_2:
    "リンクのURLルールには、'ピンのID'を挿入する {{id}} の差し込み記号と、「検知したパラメータ」のパラメータ名を{{...}}で囲った差し込み記号が使用できます。",
  HELP_LINK_URL_RULE_3:
    "例えば、 'ピンのID'が'111'で、「検知したパラメータ」にcodeという名前で'0001'の値がセットされている場合は下記の挙動になります。",
  HELP_LINK_URL_RULE_4:
    "[ https://shop.pinn-gu.com/admin/order/{{id}}/{{code}} ] と設定",
  HELP_LINK_URL_RULE_5:
    "'ピンのID'をクリックした時の遷移先は https://shop.pinn-gu.com/admin/order/111/0001",

  HELP_PROJECT_1: "全てのトラッキングデータはプロジェクト単位で保持されます。",
  HELP_PROJECT_2:
    "トラッキングデータを混同させたくない場合、プロジェクトを分割する事でそれぞれデータを管理できます。",

  HELP_DOMAIN_1:
    "入力したドメインとそのサブドメインが利用可能なドメインとなります。",
  HELP_DOMAIN_2: "例) 「*.pinn-gu.com」と登録した場合、",
  HELP_DOMAIN_3:
    "pinn-gu.com、www.pinn-gu.com、shop.pinn-gu.com  など... ←利用可能",
  HELP_DOMAIN_4: "pinn-gu.jp ←利用不可",

  HELP_USER_ID_1:
    "「ユーザーの識別子」に同じ値を挿入したユーザーは同一のユーザーと判定します。",
  HELP_USER_ID_2:
    "例えばメンバーログイン完了後のページにおいて、システムで管理しているメンバーIDを「ユーザーの識別子」に挿入する事で、異なる端末でログインしたユーザーを同一ユーザーとして扱う事ができます。",
  HELP_USER_ID_3:
    '「ユーザーの識別子」を挿入したパラメータタグの例を下記に記します。「ユーザーの識別子」が不明な場合は空文字("")のままとして下さい。',

  HELP_PINS_ID_1:
    "ピン到達の対象ページにて、「ピンの識別子」にトランザクションIDを挿入する事で、ピンの到達データと実際のトランザクションデータを紐付ける事ができます。",
  HELP_PINS_ID_2:
    "例えばお申し込み完了後のページにおいて、お申し込みを特定するIDを「ピンの識別子」に挿入する事でピンの到達とお申込みデータが同一のものと判断できるようになります。",
  HELP_PINS_ID_3:
    '「ピンの識別子」を挿入した例を下記に記します。不明な場合は空文字("")のままとして下さい。',

  HELP_PINS_PARAM_1:
    "ピン到達の対象ページにて、「ピンの付加情報」に任意の情報を渡す事でピンの到達データにより多くの情報を付加できます。",
  HELP_PINS_PARAM_2:
    "例えば商品のご購入完了後のページにおいて、商品名や商品価格、商品個数等を「ピンの付加情報」に挿入する事でピンの到達データに紐付けて付加情報を参照する事ができます。",
  HELP_PINS_PARAM_3:
    "「ピンの付加情報」を挿入した例を下記に記します。不明な場合は空({})のままとして下さい。",
  HELP_PINS_PARAM_4:
    "※商品名が「サプリメントA」、商品価格が「980」、商品個数が「2」の情報を挿入した例です。",
  HELP_PINS_PARAM_5:
    "※計測の不具合を避けるため、jsonのキーは半角英数字で指定してください。",

  HELP_TARGET_ITEM:
    "対象の要素があるページを開き、右クリックのメニュー「ページのソースを表示」やGoogle Chromeの開発者ツール等を使用して、要素の属性値（id、class、name等..）の値をご確認ください。",

  HELP_TEST_MODE:
    "テストモードを起動し、「接続元IPアドレス」で指定したIPアドレスから計測対象のページにアクセスする事で、即座にトラッキングログの参照を行えます。",

  TXT_REGISTED: "が作成されました",
  TXT_DELETED: "が削除されました",
  TXT_UPDATED: "が更新されました",
  TXT_COPY_TO: "をコピーします",
  TXT_CONFIRM_REGIST: "を作成します。",
  TXT_CONFIRM_UPDATE: "を更新します。",
  TXT_CONFIRM_DELETE: "を削除します。この操作は取り消しできません。",
  TXT_CONFIRM_DELETE_PROJECT_BODY:
    "このプロジェクトの全てのデータは参照できなくなります。",
  TXT_CONFIRM_UPDATE_DOMAIN_BODY:
    "現在までのドメインでのトラッキングが行えなくなる可能性があります。十分に注意して変更してください。",
  TXT_CONFIRM_LIMIT_ADD_TITLE: "プラン上限の変更を申請します",
  TXT_CONFIRM_LIMIT_ADD_BODY: "当月に上限の引き下げは行えません。ご注意ください。",
  TXT_PASSWORD_NOTICE: "変更する場合のみ入力してください",
  TXT_ACTIONS_NOTICE_1: "ピン到達以前",
  TXT_ACTIONS_NOTICE_2: "日までの行動を表示します。",
  TXT_IP_NOTICE: "カンマ(,)区切りで複数指定できます。",
  TXT_SHOWTO: "を見る",
  TXT_COMPANY_NAME_NOTICE:
    "会社名と担当者名を省略した場合は、組織の名前と管理者の名前を使用します。",
  TXT_ACTION_PAGE_NOTICE:
    "登録がない場合は、計測タグを貼ったすべてのページが計測対象となります。",
  TXT_BLOCK_PAGE_NOTICE:
    "「除外ページのURL」は「対象ページのURL」より優先され適用されます。",
  TXT_URL_PART_NOTICE:
    "「計測対象ページ」から外れている場合は一致されませんのでご注意ください。",
  TXT_EVENT_TARGET_NOTICE_1:
    "動的に追加される要素は取得できない可能性があります。",
  TXT_EVENT_TARGET_NOTICE_2:
    "複数の要素が取得できる場合は先頭の要素が対象となります。",
  TXT_EVENT_PARAM_NOTICE:
    "このイベント形式には、固有の追加設定があります。必ず入力してください。",
  TXT_FORNOW_NOTICE: "後から変更ができます",
  TXT_NONPROJECT_NOTICE_1: "ようこそpinnの管理コンソールへ。",
  TXT_NONPROJECT_NOTICE_2: "プロジェクトを作成",
  TXT_NONPROJECT_NOTICE_3: "してデータの箱を用意しましょう。",
  TXT_CV_LIMIT_OVER_NOTICE:
    "現在トラッキングはストップしています。再開するには、ピン到達数の上限変更を行なってください。",

  TXT_PROJECT_CREATED_NOTICE: "早速トラッキングの設定を行いましょう。",

  TXT_LIMIT_ADD_NOTICE:
    "「現在の設定」より少ない上限に変更する場合は、翌月からの適用となります。",

  TXT_CV_NOT_REGISTED: "ピンは必ず１つ以上設定してください。",

  TXT_TRACKING_TAG_EXPLAIN_1:
    "計測対象とする全てのページを編集し、<body>タグのすぐ下に下記の「計測タグ」を貼り付けて下さい。",
  TXT_TRACKING_TAG_EXPLAIN_2:
    "計測対象のページは「計測対象ページの設定」でさらに詳細の設定が行えます。",

  TXT_TRACKING_SUB_TAG_EXPLAIN_1:
    "ピン到達の対象ページやユーザーを特定できるページでは、<body>タグ内の任意の場所に下記の「パラメータタグ」を貼り付ける事で、付加情報を送る事ができます。",
  TXT_TRACKING_SUB_TAG_EXPLAIN_2:
    "同一ページに「計測タグ」が貼り付けてある必要があります。",
  TXT_TRACKING_SUB_TAG_EXPLAIN_3:
    "「パラメータタグ」は「計測タグ」より上に貼り付けるようにしてください。",
  TXT_USER_ID_HELP: "ユーザーの識別子とは",
  TXT_PINS_ID_HELP: "ピンの識別子とは",
  TXT_PINS_PARAM_HELP: "ピンの付加情報とは",

  TXT_PINSNAME_EX: "お申し込み完了",
  TXT_EVENTSNAME_EX: "バナーAへの到達有無",
  TXT_PROJECTNAME_EX: "自社ECサイトの行動計測",

  ERR_INVALID_OPERATION: "不正な画面遷移です",
  ERR_UNEXPECTED: "予期せぬエラーが発生しました。",
  ERR_UNEXPECTED_BODY: "お手数ですが、しばらくしてから再度実行してください。",
  ERR_SECURITY:
    "時間経過や不正操作によるセキュリティエラーが発生しました。画面を再読み込みしてください。",
  ERR_SECURITY_BODY:
    "画面を再読み込みするか、ブラウザを再起動してから実行してください。",
  ERR_BROWSER:
    "セキュリティ担保のため、申請時と同一のブラウザで操作を行なってください",
  ERR_REGISTED: "既に登録されています",
  ERR_NORMAL: "エラーが発生しました",
  ERR_NOT_ALLOWED: "このページにはアクセスできません",
  ERR_PAGE_NOTFOUND: "ページが存在しません",

  ERR_LINK: "必ず下記のリンクから画面を再読み込み行なってください。",
  ERR_RELOGIN: "下記からログインを行なってください。",
  ERR_SECURITY_ATTENTION:
    "それでも解消しない場合は、一度ご利用のブラウザを再起動してから実行してください。",

  ERR_CREDIT_CARD: "カード情報をご確認ください。",
  ERR_FILE_EXPIRED: "※ファイルを表示できません",
  ERR_NOT_GET: "※取得できませんでした",
  ERR_BROWSER_MAX_FILE_SIZE:
    "このブラウザでアップロードできるファイルサイズを超えています。",
};

export const SYSTEM_CONST = {
  SELF_URL: "https://admin.pinn-gu.com/",

  CAPCHA_API_URL: "https://s.wordpress.com/mshots/v1/",
  G_RECAPCHA_SITE_KEY: "6LfHPukpAAAAADh0MfgfMV8QwYGtPciB2leBXZyr",
  NOIMAGE_PATH: "/assets/img/noimage.svg",

  SNACKBAR_SECOND_SUCCESS: 3000,
  SNACKBAR_SECOND_ERROR: 2500,
};

export const API_CONNECTION = {
  URL: "https://admin-api.pinn-gu.com/",
};

if (-1 < window.location.href.indexOf("://dev.admin.pinn-gu.com")) {
  API_CONNECTION.URL = "https://dev.admin-api.pinn-gu.com/";
  SYSTEM_CONST.SELF_URL = "https://dev.admin.pinn-gu.com/";
}
if (-1 < window.location.href.indexOf("://localhost")) {
  API_CONNECTION.URL = "http://localhost:8822/";
  SYSTEM_CONST.SELF_URL = "http://localhost:3000/";
}
